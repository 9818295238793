table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 25px;
}
/* Zebra striping */
tr:nth-of-type(odd) {
  background: #eee;
}
th {
  background: #333;
  color: white;
  font-weight: bold;
}
td, th {
  padding: 6px;
  border: 1px solid #ccc;
  text-align: left;
}
.rtable {
  display: flex;
  flex-direction: column;
  &__col {
  }
  &__list {
    padding: 0;
    list-style: none;
  }
  &__header {
    font-weight: bold;
    font-size: 1.2rem;
  }
  &__cell {
    &--tax {
      color: $red;
    }
    &--post {
      color: $purple;
    }
  }
}
@media (min-width:992px) {
  .rtable {
    justify-content: space-between;
    flex-direction: row;
    &__list {
      padding: 0;
      list-style: none;
    }
  }
}
