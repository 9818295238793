.contact-form {
  max-width: 350px;
  &__title{
    align-self: center;
  }
  &__form{
    display: flex;
    flex-direction: column;
  }
  input,textarea {
    margin-bottom: 10px;
  }
  &__submit{
    align-self: center;
    width:80px;
  }
}
